import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";

const Container = ({ children, ...props }) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: "white", dark: "gray.900" };
  const color = { light: "gray.900", dark: "white" };

  return (
    <Box maxW="7xl" mx="auto" bg={bgColor[colorMode]} color={color[colorMode]} px={3} {...props}>
      {children}
    </Box>
  );
};

export default Container;
