import React, { useEffect } from "react";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { Box, Heading } from "@chakra-ui/react";

import Layout from "../../components/Layout";
import Container from "../../components/Container";

const BookIndexPage = ({ ...props }) => {
  useEffect(() => {
    window.jQuery = $;
    window.$ = $;
  }, []);
  return (
    <>
      <Helmet>
        <title>Boka pass</title>
        {/* <script
          src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
          integrity="sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs="
          crossorigin="anonymous"
        /> */}
        <script src="https://crossfittegen.wondr.se/js/iframe/ie8.polyfils.min.js" type="text/javascript" />
        <script src="https://crossfittegen.wondr.se/js/iframe/iframeResizer.min.js" type="text/javascript" />
        <script src="https://crossfittegen.wondr.se/js/iframe/wondrv3_iframe.js?1" type="text/javascript" />
      </Helmet>
      <Layout {...props}>
        <Container py="3rem">
          <Box pb="4" textAlign="center">
            <Heading as="h1" size="xl" pb="2">
              Boka pass
            </Heading>
          </Box>
          <iframe
            id="wondr-1"
            title="Boka pass"
            className="wondr-iframe"
            src="https://crossfittegen.wondr.se/schema/"
            width="100%"
            height="1400"
            frameBorder="0"
          />
        </Container>
      </Layout>
    </>
  );
};
export default BookIndexPage;
